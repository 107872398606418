@import url(https://fonts.googleapis.com/css?family=Lobster|Teko&display=swap);
.potentiometer {
    -webkit-appearance: none;
    width: 100%;
    height: 15px;
    border-radius: 5px;  
    background: #d3d3d3;
    outline: none;
    opacity: 0.7;
    -webkit-transition: .2s;
    -webkit-transition: opacity .2s;
    transition: opacity .2s;
  }
  
  .potentiometer::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 25px;
    height: 25px;
    border-radius: 50%; 
    background: #4CAF50;
    cursor: pointer;
  }
  
  .potentiometer::-moz-range-thumb {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #4CAF50;
    cursor: pointer;
  }
/* keyframes */
/* ---------------------------------------------- */
@-webkit-keyframes text-shadow-drop-bottom{0%{text-shadow:0 0 0 transparent}100%{text-shadow:0 6px 18px rgba(0,0,0,.35)}}@keyframes text-shadow-drop-bottom{0%{text-shadow:0 0 0 transparent}100%{text-shadow:0 6px 18px rgba(0,0,0,.35)}}
@-webkit-keyframes shake-top{0%,100%{-webkit-transform:rotate(0deg);transform:rotate(0deg);-webkit-transform-origin:50% 0;transform-origin:50% 0}10%{-webkit-transform:rotate(2deg);transform:rotate(2deg)}20%,40%,60%{-webkit-transform:rotate(-4deg);transform:rotate(-4deg)}30%,50%,70%{-webkit-transform:rotate(4deg);transform:rotate(4deg)}80%{-webkit-transform:rotate(-2deg);transform:rotate(-2deg)}90%{-webkit-transform:rotate(2deg);transform:rotate(2deg)}}@keyframes shake-top{0%,100%{-webkit-transform:rotate(0deg);transform:rotate(0deg);-webkit-transform-origin:50% 0;transform-origin:50% 0}10%{-webkit-transform:rotate(2deg);transform:rotate(2deg)}20%,40%,60%{-webkit-transform:rotate(-4deg);transform:rotate(-4deg)}30%,50%,70%{-webkit-transform:rotate(4deg);transform:rotate(4deg)}80%{-webkit-transform:rotate(-2deg);transform:rotate(-2deg)}90%{-webkit-transform:rotate(2deg);transform:rotate(2deg)}}
@-webkit-keyframes vibrate{0%{-webkit-transform:translate(0);transform:translate(0)}20%{-webkit-transform:translate(-2px,2px);transform:translate(-2px,2px)}40%{-webkit-transform:translate(-2px,-2px);transform:translate(-2px,-2px)}60%{-webkit-transform:translate(2px,2px);transform:translate(2px,2px)}80%{-webkit-transform:translate(2px,-2px);transform:translate(2px,-2px)}100%{-webkit-transform:translate(0);transform:translate(0)}}@keyframes vibrate{0%{-webkit-transform:translate(0);transform:translate(0)}20%{-webkit-transform:translate(-2px,2px);transform:translate(-2px,2px)}40%{-webkit-transform:translate(-2px,-2px);transform:translate(-2px,-2px)}60%{-webkit-transform:translate(2px,2px);transform:translate(2px,2px)}80%{-webkit-transform:translate(2px,-2px);transform:translate(2px,-2px)}100%{-webkit-transform:translate(0);transform:translate(0)}}
@-webkit-keyframes text-pop-up-tr{0%{-webkit-transform:translateY(0) translateX(0);transform:translateY(0) translateX(0);-webkit-transform-origin:50% 50%;transform-origin:50% 50%;text-shadow:none}100%{-webkit-transform:translateY(-10px) translateX(10px);transform:translateY(-10px) translateX(10px);-webkit-transform-origin:50% 50%;transform-origin:50% 50%;text-shadow:0 1px 0 #ccc,0 2px 0 #ccc,0 3px 0 #ccc,0 4px 0 #ccc,0 5px 0 #ccc,0 6px 0 #ccc,0 7px 0 #ccc,0 8px 0 #ccc,0 9px 0 #ccc,0 10px 30px rgba(0,0,0,.3)}}@keyframes text-pop-up-tr{0%{-webkit-transform:translateY(0) translateX(0);transform:translateY(0) translateX(0);-webkit-transform-origin:50% 50%;transform-origin:50% 50%;text-shadow:none}100%{-webkit-transform:translateY(-10px) translateX(10px);transform:translateY(-10px) translateX(10px);-webkit-transform-origin:50% 50%;transform-origin:50% 50%;text-shadow:0 1px 0 #ccc,0 2px 0 #ccc,0 3px 0 #ccc,0 4px 0 #ccc,0 5px 0 #ccc,0 6px 0 #ccc,0 7px 0 #ccc,0 8px 0 #ccc,0 9px 0 #ccc,0 10px 30px rgba(0,0,0,.3)}}
@-webkit-keyframes text-pop-up-tr-rev{0%{-webkit-transform:translateY(0) translateX(0);transform:translateY(0) translateX(0);-webkit-transform-origin:50% 50%;transform-origin:50% 50%;text-shadow:none}100%{-webkit-transform:translateY(-10px) translateX(10px);transform:translateY(-10px) translateX(10px);-webkit-transform-origin:50% 50%;transform-origin:50% 50%;text-shadow:0 1px 0 #ccc,0 2px 0 #ccc,0 3px 0 #ccc,0 4px 0 #ccc,0 5px 0 #ccc,0 6px 0 #ccc,0 7px 0 #ccc,0 8px 0 #ccc,0 9px 0 #ccc,0 10px 30px rgba(0,0,0,.3)}}@keyframes text-pop-up-tr-rev{0%{-webkit-transform:translateY(0) translateX(0);transform:translateY(0) translateX(0);-webkit-transform-origin:50% 50%;transform-origin:50% 50%;text-shadow:none}100%{-webkit-transform:translateY(-10px) translateX(10px);transform:translateY(-10px) translateX(10px);-webkit-transform-origin:50% 50%;transform-origin:50% 50%;text-shadow:0 1px 0 #ccc,0 2px 0 #ccc,0 3px 0 #ccc,0 4px 0 #ccc,0 5px 0 #ccc,0 6px 0 #ccc,0 7px 0 #ccc,0 8px 0 #ccc,0 9px 0 #ccc,0 10px 30px rgba(0,0,0,.3)}}
@-webkit-keyframes shadow-drop-2-bottom{0%{-webkit-transform:translateZ(0) translateY(0);transform:translateZ(0) translateY(0);box-shadow:0 0 0 0 transparent}100%{-webkit-transform:translateZ(50px) translateY(-12px);transform:translateZ(50px) translateY(-12px);box-shadow:0 12px 20px -12px rgba(0,0,0,.35)}}@keyframes shadow-drop-2-bottom{0%{-webkit-transform:translateZ(0) translateY(0);transform:translateZ(0) translateY(0);box-shadow:0 0 0 0 transparent}100%{-webkit-transform:translateZ(50px) translateY(-12px);transform:translateZ(50px) translateY(-12px);box-shadow:0 12px 20px -12px rgba(0,0,0,.35)}}
@-webkit-keyframes shadow-drop-2-bottom-rev{0%{-webkit-transform:translateZ(0) translateY(0);transform:translateZ(0) translateY(0);box-shadow:0 0 0 0 transparent}100%{-webkit-transform:translateZ(50px) translateY(-12px);transform:translateZ(50px) translateY(-12px);box-shadow:0 12px 20px -12px rgba(0,0,0,.35)}}@keyframes shadow-drop-2-bottom-rev{0%{-webkit-transform:translateZ(0) translateY(0);transform:translateZ(0) translateY(0);box-shadow:0 0 0 0 transparent}100%{-webkit-transform:translateZ(50px) translateY(-12px);transform:translateZ(50px) translateY(-12px);box-shadow:0 12px 20px -12px rgba(0,0,0,.35)}}

@-webkit-keyframes shadow-drop-2-bottom-light{0%{-webkit-transform:translateZ(0) translateY(0);transform:translateZ(0) translateY(0);box-shadow:0 0 0 0 transparent}100%{-webkit-transform:translateZ(15px) translateY(-4px);transform:translateZ(15px) translateY(-4px);box-shadow:0 4px 6px -4px rgba(0,0,0,.35)}}@keyframes shadow-drop-2-bottom-light{0%{-webkit-transform:translateZ(0) translateY(0);transform:translateZ(0) translateY(0);box-shadow:0 0 0 0 transparent}100%{-webkit-transform:translateZ(15px) translateY(-4px);transform:translateZ(15px) translateY(-4px);box-shadow:0 4px 6px -4px rgba(0,0,0,.35)}}
@-webkit-keyframes shadow-drop-2-bottom-light-rev{0%{-webkit-transform:translateZ(0) translateY(0);transform:translateZ(0) translateY(0);box-shadow:0 0 0 0 transparent}100%{-webkit-transform:translateZ(15px) translateY(-4px);transform:translateZ(15px) translateY(-4px);box-shadow:0 4px 6px -4px rgba(0,0,0,.35)}}@keyframes shadow-drop-2-bottom-light-rev{0%{-webkit-transform:translateZ(0) translateY(0);transform:translateZ(0) translateY(0);box-shadow:0 0 0 0 transparent}100%{-webkit-transform:translateZ(15px) translateY(-4px);transform:translateZ(15px) translateY(-4px);box-shadow:0 4px 6px -4px rgba(0,0,0,.35)}}


/* ---------------------------------------------- */
/* .inlineBlock {
  display : inline-block !important
} */
.autoWidth {
  width: auto !important
}
.customerAddressMap {
  height: 20vh !important;
  width: 100%
}
.appLogo {
  max-height: 10vh;
}
.simpleText {font-weight: 1 !important}
.nomargin {margin: 0 !important;}
/* Positions */
.centered {
  text-align: center;
  justify-content: center;
}
.centeredNoFlex {
  align-items: 'center';
  justify-content: 'center';
}
.toTop {
  top: 0 !important;
}

.block {
  display: block !important;
  background: #FFF !important;
}

.flexy{
  display: flex;
}

.impFlex{
  display: flex !important;
}

.flexCenter {
  flex:1 1;
  align-items: center;
  justify-content: center;
}

.flexCol {
  flex-direction: "column"
}

.flexEnd {
  justify-content: flex-end;
}

.flexStart {
  align-items: flex-start;
}

.noPadding {
  padding: 0 !important;
}

.noPaddingLeft {
  padding-left: 0 !important;
}

.paddingLeft {
  padding-left: 1em;
}

.padded5 {
  padding:5vw !important;
}

.padded3 {
  padding:3vw !important;
}

.padded1 {
  padding:1vw !important;
}

.noSpace {
  margin: 0 !important; 
  padding: 0 !important;
}



.paddingHorizontal {
  padding: 0 2.5vw 0 2.5vw !important;
}

.lightPaddingHorizontal {
  padding: 0 1vw 0 1vw
}

.paddingVertical {
  padding: 1em 0 1em 0 !important;
}

.marginVertical {
  margin: 1em 0 1em 0 !important;
}
.marginHorizontal {
  margin: 0 2.5vw 0 2.5vw !important;
}

.marged5 {
  margin:5vw !important;
}

.marged3 {
  margin:3vw !important;
}

.marged2 {
  margin:2vw !important;
}

.marged1 {
  margin:1em !important;
}

.lightMargin {
  margin: .5em !important
}
.margedLeft {
  margin-left: 1vw !important;
}
.margedLeft3 {
  margin-left: 2vw !important;
}

.margedTop {
  margin-top : 1vh !important;
}


.margedright {
  margin-right: 1vw;
}

.rightAligned {
  text-align: right !important;
  justify-content: right;
}

.leftAligned {
  text-align: left !important;
  justify-content: left;
}

/* Dimensions */
.onTop {
  z-index: 99 !important
}
.allSize {
  width: 100% !important;
  height: 100% !important
}
.allWidth {
  width: 100% !important;
}

.allViewportWidth {
  width: 100vw;
}

.allHeight {
  height: 100%;
}

.widthTen {
  width: 10vw
}

.viewportHeight {
  height: 100vh;
}

.allViewportHeight {
  height: 100vh;
}

.documentFrame {
  height: 50vh;
  width: 100%
}
/* text sizes */

.txtXS {
  font-size: .8em !important;
}

.txtS {
  font-size:1vw !important;
}

.txtSM {
  font-size: 1.2vw !important
}

.txtM {
  font-size:1.5vw !important;
}
.txtLS {
  font-size: 2vw !important;
}
.txtL {
  font-size:2.5vw !important;
}
.txtXL {
  font-size: 3vw !important
}

.emDotEight {
  font-size: .8em
}

.emOne {
  font-size: 1em
}

.emOneTwo {
  font-size: 1.2em
}

.emOneFive {
  font-size: 1.5em
}


/* text style */
.negativeText {
  color: #a74230 !important;
  font-style: 'bold' !important;
}
.warningText {
  color: #e0b731 !important;
  font-style: 'bold' !important;
}
.positiveText {
  color: #333d40 !important;
  font-style: 'bold' !important;
}
.bold {
  font-style: 'bold' !important;
}

.underlined {
  text-decoration: underline;
}
/* IMPORTANT : silver MUST remain WITHOUT "!important" */
.silver{color: silver}
.mouseGrey{color: rgb(126, 125, 125)}
.red{color:#a74230}
.green{color:#347e73} /* was #2d413e */
.lightGreen{color:#23af6e !important}
.lobster{font-family: 'Lobster', cursive !important;}
.teko{font-family: 'Teko', sans-serif !important }
.tekoAll *:not(i) { font-family: 'Teko', sans-serif !important; }
.black{color:rgba(0,0,0,0.9) !important}

/* text decoration */
.bordered{
  border: 1px #414141 solid;
  border-radius: 5px;
  padding: 1.5em
}
.noBorder {
  border: none !important
}

.verticalText {
  -webkit-writing-mode: vertical-rl;
      -ms-writing-mode: tb-rl;
          writing-mode: vertical-rl;
  -webkit-text-orientation: upright;
          text-orientation: upright;
  cursor: pointer;
}
.verticalText:hover {
  background-color: rgba(0,0,0,0.5) !important
}

/* visual menu */
.cardField{border:solid #333d40 1px; padding:10%; border-radius:5px; margin:3%; box-shadow:none;}
.cardField:hover{border-color : #a74230; box-shadow: 0 2px 4px 0 rgba(34,36,38,.4),0 2px 10px 0 rgba(34,36,38,.35); background-color:rgba(0,0,0,0.7); cursor:pointer}
.cardField:hover p{color: #fff !important; -webkit-animation:text-shadow-drop-bottom .6s both;animation:text-shadow-drop-bottom .6s both}
.cardField:hover i:not(.tiny){color: #a74230 !important; -webkit-animation:shake-top .8s cubic-bezier(.455,.03,.515,.955) both;animation:shake-top .8s cubic-bezier(.455,.03,.515,.955) both}
.white{color: #fff}
/* Backgrounds */
.themeGradient {
  background-color: #333d40;
  background-image: -webkit-linear-gradient(130deg, #a03a28 15%, #333d40 55%) !important;
}
.silverGradient {
  background-image: -webkit-linear-gradient(130deg, rgb(238, 238, 238) 20%, silver 80%) !important;
}

.iconImage {
  max-width: 10vw !important
}

.whiteBkg {
  background-color: #fff !important
}

/* Effects */
.overable:hover{cursor: pointer;}
.redOnHover:hover{color:#a74230 !important}
.yellowOnHover:hover{color: yellow !important}
.vibrate:hover{-webkit-animation:vibrate .3s linear infinite both;animation:vibrate .3s linear infinite both}
.zoomOnHover:hover{-webkit-animation:shadow-drop-2-bottom .4s cubic-bezier(.25,.46,.45,.94) both;animation:shadow-drop-2-bottom .4s cubic-bezier(.25,.46,.45,.94) both}
.zoomOnHover{cursor: pointer; -webkit-animation:shadow-drop-2-bottom-rev .4s cubic-bezier(.25,.46,.45,.94) reverse both;animation:shadow-drop-2-bottom-rev .4s cubic-bezier(.25,.46,.45,.94) reverse both}

.zoomOnHoverLight:hover{-webkit-animation:shadow-drop-2-bottom-light .4s cubic-bezier(.25,.46,.45,.94) both;animation:shadow-drop-2-bottom-light .4s cubic-bezier(.25,.46,.45,.94) both}
.zoomOnHoverLight{cursor: pointer; -webkit-animation:shadow-drop-2-bottom-light-rev .4s cubic-bezier(.25,.46,.45,.94) reverse both;animation:shadow-drop-2-bottom-light-rev .4s cubic-bezier(.25,.46,.45,.94) reverse both}

.shadowedOnHover:hover{-webkit-animation:text-shadow-drop-bottom .6s both;animation:text-shadow-drop-bottom .6s both}
.textPop:hover{-webkit-animation:text-pop-up-tr .5s cubic-bezier(.25,.46,.45,.94) both;animation:text-pop-up-tr .5s cubic-bezier(.25,.46,.45,.94) both}
.textPop{-webkit-animation:text-pop-up-tr-rev .5s cubic-bezier(.25,.46,.45,.94) reverse both;animation:text-pop-up-tr-rev .5s cubic-bezier(.25,.46,.45,.94) reverse both}

/* discard semantic */
.ui.grid{
  margin : 0 !important;
  
}

/* elements */

#secondary_menu_items a {
  color: #414141
}
.appBarDivider {
  height: 3px !important;
  width: 100vw !important;
  background-image: -webkit-linear-gradient(0deg, #b43c27 45%, #333d40 65%) !important;
}
/* toggle checkboxes */
.ui.fitted.toggle.checkbox input:focus:checked~.box:before,
.ui.fitted.toggle.checkbox input:focus:checked~label:before,
.ui.fitted.toggle.checkbox input:checked ~ .box::before, .ui.fitted.toggle.checkbox input:checked ~ label::before {
    background-color: teal !important
}
.nestedSearcher > input {
  margin-right: 18px !important; 
  padding: 0px 0px 3px 0px !important;
  width: 25vw
}

.opacNestedImage > img {
  opacity: 1 !important;
}

.chartTitle {
  background-color: #414141 !important;
  color: #fff !important;
  font-size: 1.6em !important;
}
.fullscreenModal{
  left: 0 !important;
  right: 0 !important;
}
.ui.toggle.checkbox input:not(.database):checked ~ .box:before,
.ui.toggle.checkbox input:not(.database):checked ~ .coloring.red:before {
background: #333d40 !important;
}

.ui.toggle.checkbox .database:checked ~ .box:before,
.ui.toggle.checkbox .database:checked ~ .coloring.red:before {
background: #347e73 !important;
}
.accountingDocumentInput {
  width: 100%; 
  font-size: .9em
}

.accountingDocumentDepositInput {
  font-size: .9em;
}
.accountingDocumentDepositInput > input {
  text-align: right !important
}

.leaflet-container{
  height: 100%;
  width: 100%
}
.notes-textarea {
  width:95%;
  margin-left:4%; 
  height:20vh;
}

#rruleGeneratorForm {
  margin-left: 13.5vw;
}

#rruleGeneratorForm .ui.checkbox {
  align-self: center;
  margin-right: 1vw;
  font-size: 1.5vw
}
.greyAppButton{background-color:transparent !important; color:#333d40 !important; border:solid 1px #333d40 !important; border-radius:1em !important}
.greyAppButton:hover{color:#fff !important; background-color:#333d40 !important; border-color:rgb(71,71,71) !important ;box-shadow:black, 1px, .5px}


.greenAppButton{background-color:transparent !important; color:#333d40 !important; border:solid 1px #333d40 !important; border-radius:1em !important}
.greenAppButton:hover{color:#fff !important; background-color:#333d40 !important; border-color:rgb(71,71,71) !important ;box-shadow:black, 1px, .5px}


.redAppButton{background-color:transparent !important; color:#a74230 !important; border:solid 1px #a74230 !important; border-radius:1em !important}
.redAppButton:hover{color:#fff !important; background-color:#a74230 !important; border-color:rgb(71,71,71) !important ;box-shadow:black, 1px, .5px}

.appButton {background-color: #414141 !important; color: #fff !important}
.appButton:hover {background-color: rgba(167, 66, 48, 1) !important; color: #fff !important}
/* SLIDER */
.gallery-item{box-shadow: 5px 10px 5px 2px rgb(163, 163, 163)}
a:hover{color:rgba(167, 66, 48, 1) !important; cursor:pointer}
.slick-dots li button {
  text-indent: -9999px;
  background:transparent;
  border: 2px rgba(167, 66, 48, 1) solid;
  border-radius:50%;
  margin: 5px;
  display:flex
}
.slick-dots{background-color:rgba(0,0,0,0.5); padding:1%}
.slick-dats {
  display:flex !important;
  justify-content:center
}
.slick-dats .slick-active{background-color:rgba(167, 66, 48, 1) !important}
li{list-style-type: none;}
.slick-dots .slick-active{background-color:rgba(167, 66, 48, 1) !important; border-radius:50%;}

/* realisation form */
#newRealisationForm input, #newRealisationForm textarea, #newRealisationForm .dropdown:not(i)  {
  width: 30vw !important
}

/* TABLE */
.ui.celled.table th, .anthraciteBkg{
  font-size: .9em !important;
  background-color: #414141;
  color: #fff
}



.controlledPaddedGrid > .column:not(.row) {
  padding: .3em !important
}


